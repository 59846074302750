.image-container {
    position: relative;
    width: 100%;
    height: 100vh; /* 100% of viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.full-screen-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensure the image fits within the container without cropping */
}